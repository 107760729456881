// Pinched from here: https://github.com/cultureamp/conversations-ui/blob/main/apps/conversations/src/utils/timezone.ts

let userTimezone = "UTC"

async function lookupUserTimezone() {
  // this should work 99.9% of time but we have reports that in some cases it returns undefined
  // we know that it can technically happen in deprecated browsers, but also sometimes in chrome. Possibly because of some privacy settings
  const fromIntl = Intl.DateTimeFormat().resolvedOptions().timeZone
  if (fromIntl) {
    userTimezone = fromIntl
  } else {
    // fallback to lookup with jstz which uses dates in time to determine the timezone
    const jstz = await import("jstz")
    userTimezone = jstz.determine().name()
  }
}

lookupUserTimezone()

const getCurrentTimeZone = () => userTimezone

export default getCurrentTimeZone

import { OneColumn } from "src/components/LayoutContainers/LayoutContainers"
import { TileContainer } from "src/components/TileBlock"
import { TileLoadingLarge } from "src/components/TileLoading"
import React from "react"

export const RetentionRateLoading = () => {
  return (
    <TileContainer>
      <TileLoadingLarge />
    </TileContainer>
  )
}

import { LoadingList } from "src/components/LoadingList/LoadingList"
import { SkirtCard } from "@kaizen/components"
import { useTaskListData } from "./api/useTaskListData"
import { useTileNameTrackerContext } from "src/components/TileDisplayedTracker/TileDisplayedTracker"
import { useTrackOnView } from "@cultureamp/analytics"
import dynamic from "next/dynamic"

const TaskList = dynamic(() => import("./components/TaskList/TaskList"), {
  loading: () => <LoadingList />,
})
const TaskListError = dynamic(
  () => import("./components/TaskList/TaskListError"),
  { loading: () => <LoadingList /> },
)

const TasksTile: React.VFC<{
  hasOldTasks?: boolean
  isWarmup?: boolean
}> = ({ isWarmup, hasOldTasks }) => {
  const tasks = useTaskListData({ filter: "active", numberOfTasks: 5 })
  const { recordTilesDisplayed } = useTileNameTrackerContext()

  useTrackOnView(() => recordTilesDisplayed([`taskListTile`]))

  return (
    <SkirtCard>
      {isWarmup || tasks.isLoading ? (
        <LoadingList />
      ) : !tasks.data ? (
        <TaskListError />
      ) : (
        <TaskList tasks={tasks} hasOldTasks={hasOldTasks} />
      )}
    </SkirtCard>
  )
}

export default TasksTile

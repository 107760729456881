import { useQuery } from "@cultureamp/frontend-apis"
import dynamic from "next/dynamic"
import styles from "./TitleTile.module.scss"

const Loading = () => <div className={styles.loadingPlaceholder} />

const HomeTitleBlock = dynamic(
  () => import("src/components/HomeTitleBlock/HomeTitleBlock"),
  { loading: Loading },
)

const TitleTile: React.FC<{
  hasFeedbackCTA?: boolean
  hasContinuousPerformanceAnytimeFeedback?: boolean
  isWarmup?: boolean
}> = ({
  hasFeedbackCTA,
  hasContinuousPerformanceAnytimeFeedback,
  isWarmup,
}) => {
  const { isLoading } = useQuery("configuration")

  if (isWarmup || isLoading) {
    return <Loading />
  }

  return (
    <HomeTitleBlock
      hasFeedbackCTA={hasFeedbackCTA}
      hasContinuousPerformanceAnytimeFeedback={
        hasContinuousPerformanceAnytimeFeedback
      }
    />
  )
}

export default TitleTile

import { TileLoadingMedium } from "src/components/TileLoading"
import { useAdminTileContext } from "src/components/OrganizationSection/AdminTileTracker"
import { useQuery } from "@cultureamp/frontend-apis"
import { useTileNameTrackerContext } from "../../components/TileDisplayedTracker/TileDisplayedTracker"
import { useTrackOnView } from "@cultureamp/analytics"
import React from "react"
import dynamic from "next/dynamic"

const SurveyStatsTile = dynamic(
  () => import("./components/SurveyStatTile/SurveyStatsTile"),
  { loading: () => <TileLoadingMedium /> },
)
const SurveyInfoTile = dynamic(
  () => import("./components/SurveyInfoTile/SurveyInfoTile"),
  { loading: () => <TileLoadingMedium /> },
)

const SurveyTile: React.FC<{ isWarmup?: boolean }> = ({ isWarmup }) => {
  const surveyTileData = useQuery("tiles/survey")

  const { recordTilesDisplayed } = useTileNameTrackerContext()
  const adminTileContext = useAdminTileContext()

  useTrackOnView(
    () =>
      adminTileContext
        ? adminTileContext.recordAdminTilesDisplayed([
            `surveyTile.${surveyTileData.data?.id}`,
          ])
        : recordTilesDisplayed([`surveyTile.${surveyTileData.data?.id}`]),
    !surveyTileData.error && Boolean(surveyTileData.data),
  )

  if (isWarmup || surveyTileData.isLoading) {
    return <TileLoadingMedium />
  }

  if (surveyTileData.error || !surveyTileData.data) return null

  // Typeguard for SurveyStatTileData
  if ("isDataTile" in surveyTileData.data && surveyTileData.data.isDataTile) {
    return <SurveyStatsTile {...surveyTileData.data} />
  }

  // Typeguard for SurveyInfoTileData
  if (!("isDataTile" in surveyTileData.data)) {
    return <SurveyInfoTile {...surveyTileData.data} />
  }

  return null
}

export default SurveyTile

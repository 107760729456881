import { ampli } from "src/ampli"
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react"

interface ContextValue {
  recordAdminTilesDisplayed: (tileNames: string[]) => void
}

const AdminTileContext = createContext<ContextValue | null>(null)

const useAdminTileReducer = () => {
  const initialState = new Set<string>()

  const reducer = (prev: typeof initialState, names: string[]) => {
    return new Set([...prev, ...names])
  }

  return useReducer(reducer, initialState)
}

export const AdminTileTracker: React.FC<
  PropsWithChildren<{ numberOfTilesShown: number }>
> = ({ children, numberOfTilesShown }) => {
  const [adminTilesNamesDisplayed, recordAdminTilesDisplayed] =
    useAdminTileReducer()

  useEffect(() => {
    if (adminTilesNamesDisplayed.size === numberOfTilesShown) {
      ampli.homepageAdminTilesViewed({
        "Tiles names displayed": [...adminTilesNamesDisplayed],
      })
    }
  }, [numberOfTilesShown, adminTilesNamesDisplayed])

  return (
    <AdminTileContext.Provider value={{ recordAdminTilesDisplayed }}>
      {children}
    </AdminTileContext.Provider>
  )
}

export const useAdminTileContext = () => useContext(AdminTileContext)

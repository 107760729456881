import { ampli } from "src/ampli"
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react"

interface ContextValue {
  recordTilesDisplayed: (names: string[]) => void
}

const TileNamesTrackerContext = createContext<ContextValue>({
  recordTilesDisplayed: () => {},
})

export const TileDisplayedTracker: FC<
  PropsWithChildren<{ numberOfTilesShown: number }>
> = ({ children, numberOfTilesShown }) => {
  const [tilesNamesDisplayed, recordTilesDisplayed] = useReducer(
    (prev: Set<string>, names: string[]) => new Set([...prev, ...names]),
    new Set<string>(),
  )

  useEffect(() => {
    if (tilesNamesDisplayed.size === numberOfTilesShown) {
      ampli.homepageTilesViewed({
        "Number of tiles displayed": tilesNamesDisplayed.size, // TODO: Remove from this event in Avo/Amplitude
        "Tiles names displayed": [...tilesNamesDisplayed], // TODO: Remove from this event in Avo/Amplitude
      })
    }
  }, [numberOfTilesShown, tilesNamesDisplayed])

  return (
    <TileNamesTrackerContext.Provider value={{ recordTilesDisplayed }}>
      {children}
    </TileNamesTrackerContext.Provider>
  )
}

export const useTileNameTrackerContext = () =>
  useContext(TileNamesTrackerContext)

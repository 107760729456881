import { TileLoadingMedium } from "src/components/TileLoading"
import { useQuery } from "@cultureamp/frontend-apis"
import React from "react"
import dynamic from "next/dynamic"

const PerformanceTileUI = dynamic(() => import("./PerformanceTileUI"), {
  loading: () => <TileLoadingMedium />,
})

const PerformanceTile: React.FC<{ isWarmup?: boolean }> = ({ isWarmup }) => {
  const { data, isLoading } = useQuery("tiles/performance")
  if (isWarmup || isLoading) {
    return <TileLoadingMedium />
  }
  return <PerformanceTileUI cycle={data?.cycles[0]} />
}

export default PerformanceTile

import { TileLoadingSmall } from "src/components/TileLoading"
import {
  blog,
  community,
  directReports,
  directory,
  oneOnOne,
  skillsCoach,
  support,
  teams,
  training,
} from "./api/data"
import React from "react"
import dynamic from "next/dynamic"
import type { Tile as TileInterface } from "src/tiles/ExploreTiles/api/data"

const Tile = dynamic(() => import("./components/Tile/Tile"), {
  loading: () => <TileLoadingSmall />,
})

export type AdditionalProps = { isWarmup?: boolean; isInFeed?: boolean }

type WithAdditionalProps = React.VFC<AdditionalProps>

const TileFacade: React.VFC<TileInterface & { isWarmup?: boolean }> = ({
  isWarmup,
  ...options
}) => {
  if (isWarmup) return <TileLoadingSmall />

  return <Tile {...options} />
}

export const TeamsTile: WithAdditionalProps = p => (
  <TileFacade {...p} {...teams} />
)
export const SupportTile: WithAdditionalProps = p => (
  <TileFacade {...p} {...support} />
)
export const OneOnOneTile: WithAdditionalProps = p => (
  <TileFacade {...p} {...oneOnOne} />
)
export const BlogTile: WithAdditionalProps = p => (
  <TileFacade {...p} {...blog} />
)
export const TrainingTile: WithAdditionalProps = p => (
  <TileFacade {...p} {...training} />
)
export const DirectoryTile: WithAdditionalProps = p => (
  <TileFacade {...p} {...directory} />
)
export const SkillsCoachTile: WithAdditionalProps = p => (
  <TileFacade {...p} {...skillsCoach} />
)
export const CommunityTile: WithAdditionalProps = p => (
  <TileFacade {...p} {...community} />
)
export const DirectReportsTile: WithAdditionalProps = p => (
  <TileFacade {...p} {...directReports} />
)

import { AdditionalProps } from "../../ExploreTiles"
import { TileLoadingSmall } from "src/components/TileLoading"
import { useQuery } from "@cultureamp/frontend-apis"
import dynamic from "next/dynamic"

const ThreeSixtyTileUI = dynamic(() => import("./ThreeSixtyTileUI"), {
  loading: () => <TileLoadingSmall />,
})

const ThreeSixtyTile: React.VFC<AdditionalProps> = ({ isWarmup, isInFeed }) => {
  const { data, isLoading } = useQuery("tiles/three-sixty")

  if (isWarmup || isLoading) return <TileLoadingSmall />

  if (!data) return null

  return (
    <ThreeSixtyTileUI
      availableThreeSixties={data.availableThreeSixties}
      isInFeed
    />
  )
}

export default ThreeSixtyTile

import {
  BlogTile,
  CommunityTile,
  DirectReportsTile,
  DirectoryTile,
  OneOnOneTile,
  SkillsCoachTile,
  SupportTile,
  TeamsTile,
  TrainingTile,
} from "src/tiles/ExploreTiles/ExploreTiles"
import { TileGrid } from "@kaizen/components"
import React from "react"
import ThreeSixtyTile from "src/tiles/ExploreTiles/components/ThreeSixtyTile/ThreeSixtyTile"
import type { HomeLayoutFlags } from "pages/api/home/types"

type ExploreTileProps = {
  flags:
    | Pick<
        HomeLayoutFlags,
        | "hasEffectiveness"
        | "hasAvailableThreeSixties"
        | "hasPerformance"
        | "hasPerformanceTeams"
        | "userHasDirectReports"
        | "hasOneOnOneAccess"
        | "hasSkillsCoachEnabled"
        | "hasSkillsCoachAccess"
      >
    | undefined
    | null
}

function getAvailableExploreTiles(flags: ExploreTileProps["flags"]) {
  const availableExploreTiles = []

  if (flags?.hasEffectiveness && flags?.hasAvailableThreeSixties) {
    availableExploreTiles.push(ThreeSixtyTile)
  }
  if (flags?.hasPerformance) {
    availableExploreTiles.push(DirectoryTile)
  }
  if (flags?.hasPerformance && flags?.hasPerformanceTeams) {
    availableExploreTiles.push(TeamsTile)
  }
  availableExploreTiles.push(SupportTile)
  if (flags?.hasPerformance && flags?.userHasDirectReports) {
    availableExploreTiles.push(DirectReportsTile)
  }
  if (flags?.hasOneOnOneAccess) {
    availableExploreTiles.push(OneOnOneTile)
  }
  if (flags?.hasSkillsCoachAccess && flags?.hasSkillsCoachEnabled) {
    availableExploreTiles.push(SkillsCoachTile)
  }
  availableExploreTiles.push(BlogTile, CommunityTile, TrainingTile)
  return availableExploreTiles.slice(0, 4)
}

const ExploreTileSection: React.FC<ExploreTileProps> = ({ flags }) => {
  const availableExploreTiles = getAvailableExploreTiles(flags)

  return (
    <section className="mt-24">
      <TileGrid>
        {availableExploreTiles.map(ExploreTileItem => {
          return <ExploreTileItem key={ExploreTileItem.name} isInFeed />
        })}
      </TileGrid>
    </section>
  )
}

export default ExploreTileSection

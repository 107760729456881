import { TileLoadingMedium } from "src/components/TileLoading"
import { useQuery } from "@cultureamp/frontend-apis"
import dynamic from "next/dynamic"

const EmployeeDataImportTile = dynamic(
  () => import("./EmployeeDataImportTile"),
  { loading: () => <TileLoadingMedium /> },
)
const EmployeeDataStatusTile = dynamic(
  () => import("./EmployeeDataStatusTile"),
  { loading: () => <TileLoadingMedium /> },
)

const EmployeeDataTile: React.VFC<{ isWarmup?: boolean }> = ({ isWarmup }) => {
  const { data, isLoading } = useQuery("tiles/employee-data")

  if (isWarmup || isLoading) {
    return <TileLoadingMedium />
  }

  if (!data) return null

  if (data.hasImportedEmployeeData) {
    return <EmployeeDataStatusTile {...data} />
  }

  return <EmployeeDataImportTile {...data} />
}
export default EmployeeDataTile
